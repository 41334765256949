import React from "react"
import Burger from "../images/ham11.png"

export default () => (
  <nav>
    <img
      id="ham_menu"
      alt="hamburger menu return to home navigation screen "
      src={Burger}
    />
  </nav>
)
